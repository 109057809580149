import React from "react";
import PropTypes from "prop-types";
import Layout from "components/layout";
import { graphql } from "gatsby";
import LostIntro from "components/lost-found-page/lost-intro";
import IntroWhatWeDo from "components/lost-found-page/lost-what-we-do";
import IntroMonthlyUpdates from "components/lost-found-page/lost-monthly-updates";

function LostFound({ data }) {
  const posts = data.posts.edges.map(p => p.node);
  const updatesPosts = posts.filter(p => p.frontmatter.title !== "index");
  const indexPost = data.main;
  return (
    <Layout>
      <LostIntro />
      <IntroWhatWeDo text={indexPost} />
      <IntroMonthlyUpdates nodes={updatesPosts} />
    </Layout>
  );
}

LostFound.propTypes = {
  data: PropTypes.object
};

export default LostFound;

export const pageQuery = graphql`
  query TechAdvocacy($locale: String!) {
    posts: allMdx(
      filter: {
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: { regex: "/content/lost-found/" }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            desc
            type
            dataset
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 260
                  placeholder: BLURRED
                )
              }
            }
          }
          fields {
            slug
          }
          body
        }
      }
    }
    main: mdx(
      frontmatter: { title: { eq: "index" } }
      fileAbsolutePath: { regex: "/lost-found/" }
      fields: { locale: { eq: $locale } }
    ) {
      body
    }
  }
`;
